<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-header py-3">
        <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          Transfer enrolment/s to a new enroller
        </h3>
      </div>
      </div>
      <div class="card-body" style="min-height: 100vh; color: antiquewhite">
        <div class="row">
          <div class="col-lg-9 col-xl-12">
            <label class="m-0" style="color: black; font-size: 12px;">
              Select the Enroller for the existing enrolment. You’ll be able to select one, or multiple candidates to transfer.
            </label>
          </div>
          <v-col  cols="12" sm="6">
            <v-autocomplete @change="changeOldAccountHolder" :items="old_accountHolders" :search-input.sync="oldAccountHolderSearch" v-model="oldAccountHolderID"
                            :error="$v.oldAccountHolderID.$error" label="Select current enroller" item-text="custom_name_with_email" item-value="id" clearable outlined dense cache-items>
            </v-autocomplete>
            <div class="text-danger" v-if="$v.oldAccountHolderID.$error">This information is required</div>
          </v-col>
          <v-col class="d-flex" cols="12" sm="6">
            <v-autocomplete  @change="changeNewAccountHolder" :items="new_accountHolders" :search-input.sync="newAccountHolderSearch" v-model="newAccountHolder" :error="$v.newAccountHolder.$error"
                label="Select enroller who is receiving candidate" outlined clearable dense item-text="custom_name_with_email" return-object>
            </v-autocomplete>
            <span class="text-danger" v-if="$v.newAccountHolder.$error">This information is required</span>
          </v-col>
          <v-row>
            <v-col cols="12" sm="6" >
              <v-card v-if="oldAccountHolderID && candidates.length > 0">
                <div class="form-group row">
                  <label class="col-xl-12 col-lg-12 col-form-label">Select candidate/s to be transferred</label>
                </div>
                <div class="form-group row" >
                  <div class="col-lg-12 col-xl-12" >
                    <v-checkbox label="Select all" color="indigo" v-model="selectAll" @click="select"></v-checkbox>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-lg-12 col-xl-12">
                    <div class="checkbox-single" v-for="candidate in candidates">

                      <label class="checkbox">
                        <i class="form-icon"></i>

<!--                        <v-checkbox  color="indigo" :value="candidate.id" v-model="select_candidates" @click="updateCheckall"></v-checkbox>-->
                        <v-alert outlined style="width: 100%"  border="left"
                                 colored-border color="purple"

                            prominent >
                          <input style="margin-right:20px " type="checkbox" :value="candidate.id" v-model="select_candidates" @change='updateCheckall()'>  {{candidate.full_name}}
                          <p style="margin-left: 35px; margin-top: 4px"> Enrolment key: <b>{{candidate.exam_key}}</b> ({{candidate.product_type_name}})</p>
                        </v-alert>
<!--                        <v-alert text dense    border="bottom"  elevation="2"
                                 color="pink darken-1"> {{candidate.full_name}} <br>

                        </v-alert>-->
                      </label>
                    </div>
                    <span class="text-danger font-weight-medium" v-if="$v.select_candidates.$error">Select candidate to transfer.</span>
                  </div>
                </div>
              </v-card>
              <v-card v-if="oldAccountHolderID && candidates.length==0">
                <div class="form-group row">
                  <label class="col-xl-12 col-lg-12 col-form-label text-center">No Candidates Available</label>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" >
              <v-card v-if="newAccountHolder">
                <div class="form-group row">
                  <label class="col-xl-12 col-lg-12 col-form-label">Summary of enroller who will become the owner of the enrolments selected
                  </label>
                </div>
                <div class="form-group row">
                  <label class="col-xl-4 col-lg-4 col-form-label text-right">Full name:</label>
                  <div class="col-lg-8 col-xl-6">
                    <span class="font-weight-bold">{{newAccountHolder.full_name}}
                    </span>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-xl-4 col-lg-4 col-form-label text-right">Email:</label>
                  <div class="col-lg-8 col-xl-6">
                    <span class="font-weight-bold">{{newAccountHolder.email}}</span>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-xl-4 col-lg-4 col-form-label text-right">Score:</label>
                  <div class="col-lg-8 col-xl-6">
                    <span class="font-weight-bold">{{newAccountHolder.score_name}}
                    </span>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-xl-4 col-lg-4 col-form-label text-right">Comment:</label>
                  <div class="col-lg-8 col-xl-6">
                    <v-textarea
                      label="Transfer comment"
                      outlined
                      dense
                      v-model="candidateTransferComment"
                    ></v-textarea>
                  </div>
                </div>

                <div class="form-group row">
                  <v-col cols="12" sm="6" md="9" onmousedown='return false;' onselectstart='return false;'>
                    <v-text-field label="Confirmation*" v-model="validation_text" dense outlined></v-text-field>
                    Please type  <code class="ma-2" style="color: black" >
                    confirm merge
                  </code>
                    to continue
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <v-btn @click="transferAccountHolder" :disabled="validation_text!='confirm merge'" depressed color="primary">Confirm transfer</v-btn>
                  </v-col>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </div>
      <v-overlay :value="transfer_overlay">
        <v-progress-circular
            indeterminate
            size="64"
        ></v-progress-circular>
      </v-overlay>
    </div>

  </v-app>
</template>
<style scoped>
.v-alert:not(.v-sheet--tile){
  border-radius: 6px;
}
</style>
<script>
import AccountHolderService from "@/services/user/accountholder/AccountholderService";
import CandidateService from "@/services/candidate/CandidateService";
import {required, requiredIf} from "vuelidate/lib/validators";

const candidate=new CandidateService();
const accountHolder=new AccountHolderService();
export default {
  validations: {
    newAccountHolder:{required},
    oldAccountHolderID:{required},
    select_candidates:{
      required: requiredIf(function (nestedModel){
        return this.oldAccountHolderID && this.select_candidates.length==0;
      }),
    },

  },
  data() {
    return {
      transfer_overlay:false,
      old_accountHolders:[],
      new_accountHolders:[],
      oldAccountHolderID:'',
      newAccountHolder:false,
      checkAll:false,
      isLoading:false,
      oldAccountHolderSearch:'',
      newAccountHolderSearch:'',
      candidates:[],
      select_candidates:[],
      selectAll: false,
      validation_text:'',
      candidateTransferComment: ''
    }
  },
  methods:{
    async searchOldAccountHolder(data) {
      accountHolder
          .search(data)
          .then((response) => {
            this.old_accountHolders = response.data.accountHolders;
          })
          .catch(err => {

          })
          .finally(() => (this.isLoading = false))
    },
    async searchNewAccountHolder(data) {
      accountHolder
          .search(data)
          .then((response) => {
            this.new_accountHolders = response.data.accountHolders;
          })
          .catch(err => {

          })
          .finally(() => (this.isLoading = false))
    },
    changeOldAccountHolder(){

      this.findAccountHolderCandidates();
      this.checkDuplicateAccountHolder();
    },
    changeNewAccountHolder(){
      this.checkDuplicateAccountHolder();
    },
    findAccountHolderCandidates(){


      this.transfer_overlay=true;
      candidate
          .getAllAccountHolderCandidates(this.oldAccountHolderID)
          .then((response) => {
            this.candidates = response.data.candidates;
          })
          .catch(err => {

          })
          .finally(() => {
            this.transfer_overlay=false;
          });

    },
    select() {
      this.select_candidates = [];
      if (this.selectAll) {
        for (let i in this.candidates) {
          this.select_candidates.push(this.candidates[i].id);
        }
      }
    },
    checkDuplicateAccountHolder(){
      if(this.newAccountHolder && this.newAccountHolder.id && this.oldAccountHolderID){
        if(this.newAccountHolder.id==this.oldAccountHolderID){
            this.$snotify.error("Unable to move, same enroler selected");
            this.oldAccountHolderID=null;
            this.newAccountHolder=null;
            this.candidates=[];
        }
      }
    },
    transferAccountHolder(){
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        let data = {
          'candidate_ids': this.select_candidates,
          'owner_id': this.newAccountHolder.id,
          'comment': this.candidateTransferComment
        }
        this.transfer_overlay=true;
        candidate
            .transferMultipleForAccountHolder(data)
            .then((response) => {
              this.findAccountHolderCandidates();
              if(this.candidates.length==0){
                this.$v.$reset();
                this.oldAccountHolderID=null;
                this.newAccountHolder=null;
              }
              this.$snotify.success("Candidates migrated across the enroler");
            })
            .catch(err => {

            })
            .finally(() => {
              this.candidateTransferComment = '';
              this.transfer_overlay=false;
            });
      }
    },
    updateCheckall: function(){

      if(this.candidates.length == this.select_candidates.length){
        this.selectAll = true;
      }else{
        this.selectAll = false;
      }
    }
  },
  watch: {
    oldAccountHolderSearch(val) {
      let data = {
        info:val,
      };
      this.searchOldAccountHolder(data);
    },
    newAccountHolderSearch(val) {
      let data = {
        info:val,
      };
      this.searchNewAccountHolder(data);
    },
  }
}
</script>
